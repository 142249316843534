import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { easeInSine } from "./easings";

const mapRanges = (value, x1, y1, x2, y2) => ((value - x1) * (y2 - x2)) / (y1 - x1) + x2;
const root = document.documentElement;

/* Entry animation for intro block*/
export const introTextBlockFade = (fadingTextBlock, introLogo) => {
  gsap.from(introLogo, {
    opacity: 0,
    filter: "blur(5px)",
    scale: 0.75,
    xPercent: 15,
    yPercent: 200,
    skewX: -10,
    skewY: -5,
    rotate: 2.5,
    duration: 0.5,
    ease: "power3.in",
  });

  gsap.to(fadingTextBlock, {
    opacity: 1,
    rotate: 0,
    y: 0,
    duration: 0.75,
    ease: "power3.in",
    delay: 0.15,
  });
};

/* Fading text lines
   —————————————————————————————————
  - Fade in text lines on scroll
  - unblur text lines on scroll
*/
export const fadingTextLines = (introFadingTextLines) => {
  if (!introFadingTextLines) return;

  introFadingTextLines.forEach((line) => {
    const isIntro = line.closest(".section-intro");
    ScrollTrigger.create({
      trigger: line,
      start: isIntro ? "top 95%" : "top 85%",
      end: isIntro ? "top 65%" : "top 25%",
      ease: "power3.inOut",
      onUpdate: (self) => {
        const progress = self.progress.toFixed(2);
        line.style.opacity = progress;
        if (isIntro) {
          line.style.filter = `blur(${mapRanges(progress, 0, 1, 5, 0)}px)`;
        }
      },
    });
  });
};

/* Scrolling logos enter 
   —————————————————————————————————
  - Animate logos parent along Y axis to 0
  - Reduce gap between logos
  - Fade in logos
*/
export const animateLogosIn = (logoMarquee, logos, logoStrips, matchMedia) => {
  const isLarge = matchMedia() === "lg" || matchMedia() === "xl";

  gsap.set(logoMarquee, { yPercent: 0, xPercent: 0, opacity: 1 });
  gsap.set(logos, { gap: isLarge ? "3.5rem 0" : "0rem 0", rotate: -6 });
  gsap.set(logoStrips, { scale: isLarge ? 0.75 : 0.5 });

  gsap.from(logoMarquee, {
    scrollTrigger: {
      trigger: logoMarquee,
      start: "top 95%",
      end: "bottom 70%",
      scrub: 1.5,
    },
    yPercent: 100,
    opacity: 0,
    ease: "power3.out",
  });

  gsap.from(logos, {
    scrollTrigger: {
      trigger: logoMarquee,
      start: "top 90%",
      end: "bottom 65%",
      scrub: 1.5,
    },
    gap: isLarge ? "10rem 0" : "3.5rem 0",
    rotate: 0,
    ease: "power3.out",
  });
};

/* Intro section exit 
   —————————————————————————————————
  - Zoom in moving logos
  - Fade out moving logos
  - Blur moving logos
*/
export const animateLogosOut = (parentSection, logoMarquee, logos) => {
  gsap.to(logos, {
    scrollTrigger: {
      trigger: parentSection,
      start: "bottom 30%",
      end: "bottom top",
      scrub: 0.5,
    },
    scale: 1.05,
    yPercent: 20,
    opacity: 0.3,
    filter: `blur(5px)`,
    ease: "power3.inOut",
  });
};

/* Services section pin
   —————————————————————————————————
  - Pin services section
  - Scrub through services section
*/
export const servicesAnimation = (servicesSection, servicesContainer, services, isTouchDevice, matchMedia) => {
  const isLarge = matchMedia() === "lg" || matchMedia() === "xl";

  services.forEach((service, index) => {
    ScrollTrigger.create({
      trigger: service,
      start: `top top+=${isLarge ? 50 + index * 150 : 50 + index * 90}`,
      endTrigger: servicesContainer,
      end: `bottom top`,
      scrub: 1,
      pin: true,
      anticipatePin: 1,
      id: "card-pin",
      ease: "linear",
      onUpdate: (self) => {
        const progress = self.progress.toFixed(2);
        service.style.transform = `scale(${mapRanges(progress, 0, 1, 1, index / 10 + 0.7)})`;
      },
    });
  });

  if (isTouchDevice) {
    // For some reason, adding blur filter will break on Desktop, except Safari (Chrome & FF breaks)
    // so for now, let's only add blur filter on touch devices
    ScrollTrigger.create({
      trigger: servicesSection,
      start: "bottom bottom-=50%",
      end: `bottom top`,
      scrub: 1,
      ease: "linear",
      onUpdate: (self) => {
        const progress = self.progress.toFixed(2);
        servicesContainer.style.filter = `blur(${mapRanges(progress, 0, 1, 0, 20)}px)`;
      },
    });
  }
};

/* Contact section scrolling text
   —————————————————————————————————
  - Scroll text horizontally
*/
export const contactAnimation = (section, chars, hiddenSection) => {
  const dot = chars[chars.length - 1];
  const parentBoundingBox = section.getBoundingClientRect();
  const dotBoundingBox = dot.getBoundingClientRect();
  const dotScaleFactor = Math.ceil((Math.max(window.innerHeight, window.innerWidth) / dotBoundingBox.height) * 1.4);

  const parentCenter = {
    x: parentBoundingBox.left + parentBoundingBox.width / 2,
    y: parentBoundingBox.top + parentBoundingBox.height / 2,
  };

  const dotCenter = {
    x: dotBoundingBox.left + dotBoundingBox.width / 2,
    y: dotBoundingBox.top + dotBoundingBox.height / 2,
  };

  const dotTranslateDistance = {
    x: parseInt(parentCenter.x - dotCenter.x),
    y: parseInt(parentCenter.y - dotCenter.y),
  };

  gsap.set(chars, {
    yPercent: 30,
    opacity: 0,
    scaleY: 0,
    rotate: 0,
    transformOrigin: "50% 100%",
  });

  const textRevealAnimation = gsap.to(chars, {
    yPercent: 0,
    opacity: 1,
    rotate: 0,
    stagger: 0.03,
    duration: 1,
    scaleY: 1,
    ease: "elastic.inOut(1,1.5)",
    paused: true,
  });

  ScrollTrigger.create({
    trigger: section,
    start: "top top+=60%",
    end: "bottom bottom",
    animation: textRevealAnimation,
  });

  gsap.set(dot, {
    transformOrigin: "center center",
    scale: 1,
  });

  gsap.set(hiddenSection, {
    opacity: 0,
    yPercent: 100,
  });

  const dynamicEmail = ["hi", "@", "vctr", ".", "is"];
  const targetElement = section.querySelector("[data-clipboard-copy]");
  const targetElementText = targetElement.querySelector(".text");

  let isEmailSet = false;

  ScrollTrigger.create({
    trigger: section,
    start: "top top",
    end: `+=850`,
    scrub: true,
    pin: true,
    anticipatePin: 1,
    onUpdate: (self) => {
      const oneThird = 0.33;
      const overHalf = 0.6;
      const progress = self.progress.toFixed(2);
      const clampedProgress = gsap.utils.clamp(oneThird, 1, progress);
      const clampedProgress2 = gsap.utils.clamp(overHalf, 1, progress);
      // Dynamically inject email & listen for click
      if (progress > oneThird && !isEmailSet) {
        const result = dynamicEmail.join("");
        targetElementText.innerText = result;
        targetElement.href = result;
        targetElement.addEventListener("click", (evt) => {
          evt.preventDefault();
          // NOTE: this won't work on on localhost (ios) due to non-secure context
          navigator.clipboard.writeText(result);
          targetElement.classList.add("copied", "visible");

          setTimeout(() => {
            targetElement.classList.remove("copied");
          }, 3000);

          setTimeout(() => {
            targetElement.classList.remove("visible");
          }, 3500);
        });
        isEmailSet = true;
      }
      // Clamp the progress to 0.33 to defer dot movement until we are 1/3 into the scroll (this just looks better)
      root.style.setProperty("--contact-progress", easeInSine(progress) * dotScaleFactor);
      root.style.setProperty(
        "--contact-dot-x",
        mapRanges(clampedProgress, oneThird, 1, 0, dotTranslateDistance.x) + "px"
      );
      root.style.setProperty(
        "--contact-dot-y",
        mapRanges(clampedProgress, oneThird, 1, 0, dotTranslateDistance.y) + "px"
      );

      hiddenSection.style.opacity = mapRanges(clampedProgress2, overHalf, 1, 0, 1);
      hiddenSection.style.transform = `translateY(${mapRanges(clampedProgress2, overHalf, 1, 50, 0)}px)`;
    },
  });
};
